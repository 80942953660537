export const adminPathBuilder = () => ({
  login: () => `/`,
  proposal: () => ({
    list: () => `/proposals`,
    detail: (id: string) => `/proposals/${id}`,
    request: (proposalIds: number[]) => `/proposals/request?proposal_ids=${proposalIds.join(',')}`,
  }),
  businessFactCheck: () => ({
    list: () => `/business_fact_checks`,
    detail: (id: number) => `/business_fact_checks/${id}`,
    judge: (id: number) => `/business_fact_checks/${id}/judge`,
  }),
});
